<template lang="pug">
	#section
		el-row(:gutter="24")
			el-col(:xs="24" :sm="12")
				label
					b Summery
			el-col(:xs="24" :sm="12")
				span here will be a chart

</template>

<style></style>

<script>
export default {
	name: 'visits-report'
}
</script>