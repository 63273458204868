<template lang="pug">
		#section
				#filters
						el-row(:gutter="24")
								el-col(:xs="24")
										el-form
												el-row(:gutter="10")

														el-col(:xs="12" :sm="5")
																el-form-item(label="By Keyword" label-position="top" label-width="auto")
																		el-input(type="text" size="mini", placeholder="Search By Keyword..."  v-model="paginate.keyword", v-debounce:300ms="fetchData")

														el-col(:xs="12", :sm="12", :lg="4")
																el-form-item(label="Date Range" label-position="top" label-width="auto")
																		el-date-picker.w-100(v-model='filters.range', size="mini"  type='daterange', start-placeholder='Start date', :picker-options="pickerOptions"
																				end-placeholder='End date', :default-time="['00:00:00', '23:59:59']", value-format="yyyy-MM-dd", @change="fetchData")
														el-col(:xs="12", :sm="12", :lg="4")
																el-form-item(label="Affiliate" label-position="top" label-width="auto")
																		el-select.w-100(filterable="" multiple="" v-model="filters.affiliate_id"  size="mini" :collapse-tags="true" @change="fetchData")
																				el-option(v-for="o of options.affiliates" :value="o.v" :label="o.n")
																						span {{ o.n }}&nbsp;
																						small(v-if="o.e") - ({{ o.e }})
														el-col(:xs="12", :sm="12", :lg="4")
																el-form-item(label="Campaign" label-position="top" label-width="auto")
																		el-select.w-100(filterable="" multiple="" v-model="filters.campaign_id" size="mini" :collapse-tags="true" @change="fetchData")
																				el-option(v-for="o of options.campaigns" :label="o.t"  :value="o.v")

				#table
						el-table.funnels-tbl.small-tbl-font(id="data-list", v-loading='busy', stripe="", :data='list', element-loading-text='Loading', header-row-class-name="dark-header", border='', fit='', highlight-current-row='')
								el-table-column(align='center', label='#' :width='85')
										template(slot-scope='scope')
												| {{ scope.row.id }}
								el-table-column(align='center', label='Timestamp' :width='115')
										template(slot-scope='scope')
												small {{ scope.row.date_created }}
								el-table-column(align='center', label='IPv4' :width="140")
										template(slot-scope="scope")
												span {{ scope.row.ip_addr }}
								el-table-column(align='center', label='Campaign')
										template(slot-scope="scope")
												el-tooltip()
														template(slot="content")
																span.d-block Name: {{scope.row.campaign ? scope.row.campaign.name : '-' }}
																span.d-block UUID: {{scope.row.campaign ? scope.row.campaign.uuid : '-' }}
														span.chooseable {{scope.row.campaign ? scope.row.campaign.name  : '-' }}
								el-table-column(align='center', label='Affiliate')
										template(slot-scope="scope")
												el-tooltip()
														template(slot="content")
																span.d-block Name: {{scope.row.user ? scope.row.user.username : '-' }}
																span.d-block UUID: {{scope.row.user ? scope.row.user.uuid : '-' }}
																span.d-block E-Mail: {{scope.row.user ? scope.row.user.email : '-' }}
														span.chooseable {{scope.row.campaign ? scope.row.user.username  : '-' }}
								el-table-column(align='center', label='Path' :width="200")
										template(slot-scope="scope")
												small(v-if="scope.row.path.length < 60") {{ scope.row.path }}
												el-tooltip(v-if="scope.row.path.length >= 60" :content="scope.row.path")
													small() {{ scope.row.path.substr(0,60) + '...' }}
								el-table-column(align='center', label='Payload')
										template(slot-scope="scope")
												el-popover(
														v-if="scope.row.payload"
														placement="top-start"
														title="Request Payload"
														:width="350"
														trigger="hover")
														div(v-if="typeof scope.row.payload !== 'object'")
																b.text-bold Params:&nbsp;
																		span.text-danger {{ scope.row.payload }}
														div(v-else)
																el-row(:gutter='10')
																		el-col.mt-3(v-for='r of Object.entries(scope.row.payload)', :span="24")
																				//el-form-item(:label='r[0] + ":"', label-position='left')
																				span.font-weight-bold {{r[0]}}:&nbsp;
																				span.text-danger {{r[1]}}
														span.chooseable(slot="reference") Hover to show
								el-table-column(align='center', label='HTTP Code' :width="100")
										template(slot-scope="scope")
												span(v-bind:class="{'text-danger': scope.row.response_code >= 400}") {{scope.row.response_code}}
								el-table-column(align='center', label='Response')
										template(slot-scope="scope")
												el-popover(
														v-if="scope.row.response_text"
														placement="top-start"
														title="Request Response - Parsed"
														:width="350"
														trigger="hover")
														div(v-if="typeof scope.row.response_text !== 'object'")
																b.text-bold Response:&nbsp;
																		span.text-danger {{ scope.row.response_text }}
														div(v-else)
																el-row(:gutter='10')
																		el-col.mt-3(v-for='r of Object.entries(scope.row.response_text)', :span="24")
																				//el-form-item(:label='r[0] + ":"', label-position='left')
																				span.font-weight-bold {{r[0]}}:&nbsp;
																				span.text-danger {{r[1]}}
														span.chooseable(slot="reference") Hover to show
								el-table-column(align='center', label='Params')
										template(slot-scope="scope")
												el-popover(
														v-if="scope.row.params"
														placement="top-start"
														title="Request params"
														:width="350"
														trigger="hover")
														div(v-if="typeof scope.row.params !== 'object'")
																b.text-bold Response:&nbsp;
																		span.text-danger {{ scope.row.params }}
														div(v-else)
																el-row(:gutter='10')
																		el-col.mt-3(v-for='r of Object.entries(scope.row.params)', :span="24")
																				//el-form-item(:label='r[0] + ":"', label-position='left')
																				span.font-weight-bold {{r[0]}}:&nbsp;
																				span.text-danger {{r[1]}}
														span.chooseable(slot="reference") Hover to show
						.d-flex.justify-content-between
								b-pagination.mt-3(v-model="paginate.page", :total-rows="count", :per-page="paginate.limit", aria-controls="data-list", @change="fetchData")

								el-row.mt-4
										el-col(:span="24")
												label.label-text-gray
														b Total:&nbsp;
														| {{ count | numeral('0,0') }}

								el-row
										el-col(:span="24")
												el-select.mt-3(filterable="", v-model="paginate.limit", @change="fetchData")
														el-option(label="5 Per Page", :value="5")
														el-option(label="10 Per Page", :value="10")
														el-option(label="20 Per Page", :value="20")
														el-option(label="50 Per Page", :value="50")
														el-option(label="100 Per Page", :value="100")
														el-option(label="200 Per Page", :value="200")
</template>

<style lang="scss">

</style>

<script>
export default {
		name: 'requests-report',
		data() {
			return {
				busy: false,
				fetchWatcher: null,
				list: [],
				count: 0,
				options: {
					affiliates: [],
					campaigns: []
				},
				filters: {
					range: [],
					affiliate_id: null,
					campaign_id: null
				},
				paginate: {
					keyword: '',
					limit: 5,
					page: 1
				},
				pickerOptions: {
					shortcuts: [
						{
							text: 'Today',
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime());
								picker.$emit('pick', [start, end]);
							}
						},
						{
							text: 'Last 2 Days',
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
								picker.$emit('pick', [start, end]);
							}
						},
						{
							text: 'Last week',
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
								picker.$emit('pick', [start, end]);
							}
						},
						{
							text: 'Last month',
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
								picker.$emit('pick', [start, end]);
							}
						},
						{
							text: 'Last 3 months',
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
								picker.$emit('pick', [start, end]);
							}
						}
					]
				}
			}
		},
		created() {
				this.$apix.sendHttpRequest('GET', 'components/options', { filters: '0661e754-6a3a-4abe-842d-975a2d41a871,eade7b54-f499-4265-925b-12691f7ea2e5' })
						.then(options => {
								this.options.affiliates= options.affiliates;
								this.options.campaigns = options.campaigns_options;
						})
		},
		mounted() {
				this.fetchData()
				this.fetchWatcher = setInterval(() => this.fetchData(), 60000)
		},
		beforeDestroy() {
				clearInterval(this.fetchWatcher);
		},
		methods: {
				fetchData() {
						this.busy = true;
						setTimeout(async () => {
								const form = {...this.paginate, ...this.filters};
								this.$apix.sendHttpRequest('GET', 'requests/list', form)
										.then((res) => {
												this.busy = false;
												this.list = res.rows;
												this.count = res.count;
										})
										.catch(err => this.busy = false)
						}, 200)
				}
		}
}
</script>