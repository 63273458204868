<template lang="pug">
	#section
		el-row(:gutter="24")
			el-col(:xs="24")
				el-form
					el-row(:gutter="10")

						el-col(:xs="12" :sm="5")
							el-form-item(label="By Keyword" label-position="top" label-width="auto")
								el-input(type="text",size="mini", placeholder="Search By Keyword...", v-model="paginate.keyword", v-debounce:300ms="fetchData")

						el-col(:xs="12", :sm="12", :lg="4")
							el-form-item(label="Date Range" label-position="top" label-width="auto")
								el-date-picker.w-100(v-model='filters.range', size="mini"  type='daterange', start-placeholder='Start date',  :picker-options="pickerOptions"
									end-placeholder='End date', :default-time="['00:00:00', '23:59:59']", value-format="yyyy-MM-dd", @change="fetchData")

						el-col(:xs="12",  :sm="6", :lg="3")
							el-form-item(label="Step" label-position="top" label-width="auto")
								el-select.w-100(filterable="", size="mini"  v-model='filters.step', placeholder='By Brand', @change="fetchData")
									el-option.text-bold(label='All', value='')
									el-option(v-for="o of steps_options", :label="o.t", :value="o.v")

						el-col(:xs="12",  :sm="6", :lg="3")
							el-form-item(label="Brand" label-position="top" label-width="auto")
								el-select.w-100(filterable="", size="mini"  v-model='filters.brand', placeholder='By Brand', @change="fetchData")
									el-option.text-bold(label='All', value='')
									el-option(v-for="o of brands_options", :label="o.t", :value="o.v")

						el-col(:xs="12", :sm="6" :lg="3")
							el-form-item(label="Funnel" label-position="top" label-width="auto")
								el-select.w-100(filterable="", size="mini"  v-model='filters.funnel', placeholder='By Funnel', @change="fetchData" :disabled="filters.brand === ''")
									el-option.text-bold(label='All', value='')
									el-option(v-for="o of funnels_options", :value="o.v" :label="`${o.t + (o.nt ? ` (${o.nt})` : '')} ${o.n && 1==2 ? ' - (' + o.n +')' : ''}`" v-if="filters.brand === o.b")

						el-col(:xs="12", :sm="6" :lg="3")
							el-form-item(label="Country" label-position="top" label-width="auto")
								el-select.w-100(filterable="", size="mini"  v-model='filters.country', placeholder='Country', @change="fetchData")
									el-option.text-bold(label='All', value='')
									el-option(v-for="o of country_options", :label="o.t", :value="o.v")

						el-col(:xs="12", :sm="6" :lg="3")
							el-form-item(label="Registrations" label-position="top" label-width="auto")
								//el-select.w-100(filterable="", size="mini"  v-model='filters.registrations', placeholder='Lead / Customer Registrations', @change="fetchData")
									el-option.text-bold(label="None" value="none")
									el-option.text-bold(label="Leads" value="leads")
									el-option.text-bold(label="Customers" value="customers")
									el-option.text-bold(label="Both" value="both")

								el-select.w-100(filterable="", size="mini"  v-model='filters.registrations', placeholder='Lead / Customer Registrations', @change="fetchData")
									el-option.text-bold(label="Both" value="both" selected)
									el-option.text-bold(label="Success (All)" value="SUCCESS")
									el-option.text-bold(label="Success (With Logs)" value="SUCCESS_LOGS")
									el-option.text-bold(label="Success (W/O Logs)" value="SUCCESS_WO_LOGS")
									el-option.text-bold(label="Failed" value="FAILED")
									el-option.text-bold(label="Failed (With Logs)" value="FAILED_LOGS")
									el-option.text-bold(label="Failed (W/O Logs)" value="FAILED_WO_LOGS")


		el-table.funnels-tbl.small-tbl-font(id="data-list", v-loading='busy', stripe="", :data='list', element-loading-text='Loading', header-row-class-name="dark-header", border='', fit='', highlight-current-row='')
			el-table-column(align='center', label='#' width='85')
				template(slot-scope='scope')
					| {{ scope.row.id }}
			el-table-column(align='center', label='Step' )
				template(slot-scope='scope')
					| {{ scope.row.step }}
			el-table-column(align='center', label='IP' )
				template(slot-scope='scope')
					| {{ scope.row.ip_addr }}
			el-table-column(align='center', label='Params')
				template(slot-scope='scope')
					//pre {{scope.row.params}}
					el-popover(
						placement="top-start"
						title="Title"
						:width="!scope.row.params || Object.entries(scope.row.params).length === 0 ? 200 : 350"
						trigger="hover"

						)
						//:content="JSON.stringify(Object.entries(scope.row.params), '<br>', 2)"
						el-form.mt-3
							el-row(:gutter='10')
								el-col(:span="24" v-if="!scope.row.params || Object.entries(scope.row.params).length === 0")
									| No params
								dev(v-if="scope.row.params")
									el-col.mt-3( v-for='r of Object.entries(scope.row.params)', :span="24")
										//el-form-item(:label='r[0] + ":"', label-position='left')
										span.font-weight-bold {{r[0]}}:&nbsp;
										span.text-danger {{r[1]}}
						//el-form.mt-3
							el-row(:gutter='10')
								el-col(v-for='r of currentParams', :span="24")
									el-form-item(:label='r.t', label-position='left', label-width='5rem')
										el-input(type='textarea', v-model='r.v', read-only='')
						span.chooseable(slot="reference") Hover to activate
			el-table-column(align='center', label='Timestamp' )
				template(slot-scope='scope')
					| {{ scope.row.createdAt | formatDateTime }}
			el-table-column(align='center', label='Country' )
				template(slot-scope='scope')
					//| {{ scope.row.country_object.name }}
					| {{ scope.row.country.name }}
			el-table-column(align='center', label='Brand' )
				template(slot-scope='scope')
					| {{ scope.row.brand.name }}
			el-table-column(align='center', label='Funnel' )
				template(slot-scope='scope')
					| {{ scope.row.funnel.name }}

			el-table-column(align='center', label='Visit Meta' )
				template(slot-scope='scope')
					el-popover(
						v-if="scope.row.client_log.length > 0 || scope.row.log.length > 0"
						placement="top-start"
						title="Registration entry data"
						:width="640"
						:height="300"
						trigger="hover"

					)
						div
							el-form.mt-3.scroll-elem(v-if="scope.row.client_log.length > 0 || scope.row.log.length > 0")
								el-row(:gutter='10')
									el-card.mt-3(v-for='log of scope.row.client_log', :span="24")
										el-col.mt-3(v-for='r of Object.entries(log)', :span="24")
											span.font-weight-bold {{r[0]}}:&nbsp;
											span.text-danger {{r[1]}}
								el-row(:gutter='10')
									el-card.mt-3(v-for='log of scope.row.log', :span="24")
										el-col.mt-3(v-for='r of Object.entries(log)', :span="24")
											span.font-weight-bold {{r[0]}}:&nbsp;
											span.text-danger {{r[1]}}
						span.chooseable(slot="reference") Hover To Show
					span(v-else="") -

			el-table-column(align='center', label='Registration ID' )
				template(slot-scope='scope')
					el-popover(
						v-if="scope.row.lead.length > 0"
						placement="top-start"
						title="Registration entry data"
						:width="350"
						trigger="hover"

					)
						el-form.mt-3(v-if="scope.row.lead.length > 0")
							el-row(:gutter='10')
								el-col.mt-3(v-for='r of Object.entries(scope.row.lead[0])', :span="24" v-if="r[0] !== 'headers' || r[0] !== 'payload'")
									//el-form-item(:label='r[0] + ":"', label-position='left')
									span.font-weight-bold {{r[0]}}:&nbsp;
									span.text-danger {{r[1]}}
						span.chooseable(slot="reference") {{ scope.row.lead[0].id || '-' }}
					span(v-else="") -

		//ds-paginate(:limit.sync="paginate.limit", :page.sync="paginate.page", :count="count", :func="fetchData")

		.d-flex.justify-content-between
			b-pagination.mt-3(v-model="paginate.page", :total-rows="count", :per-page="paginate.limit", aria-controls="data-list", @change="fetchData")

			el-row.mt-4
				el-col(:span="24")
					label.label-text-gray
						b Total:&nbsp;
						| {{ count | numeral('0,0') }}

			el-row
				el-col(:span="24")
					el-select.mt-3(filterable="", v-model="paginate.limit", @change="fetchData")
						el-option(label="10 Per Page", :value="10")
						el-option(label="20 Per Page", :value="20")
						el-option(label="50 Per Page", :value="50")
						el-option(label="100 Per Page", :value="100")
						el-option(label="200 Per Page", :value="200")
</template>


<style lang="scss">
	.scrol-card {
		height: 200px !important;
		overflow-y: auto !important;
	}
	.scroll-elem {
		height: 560px !important;
		overflow-y: scroll !important;
	}
</style>


<script lang="js">
	import DsPaginate from "../components/paginate";
	import moment from "moment";
	export default {
		components: {DsPaginate},
		name: 'visits-list',
		props: {
			country_options: Array,
			brands_options: Array,
			funnels_options: Array,
			steps_options: Array,
		},
		data() {
			return {
				currentParams: [],
				fetchWatcher: null,
				busy: false,
				list: [],
				count: 0,
				paginate: {
					page: 1,
					keyword: '',
					limit: 10
				},
				filters: {
					step: '',
					brand: '',
					range: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
					funnel: '',
					country: '',
					registrations: 'both'
				},
				pickerOptions: {
					shortcuts: [
						{
							text: 'Today',
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime());
								picker.$emit('pick', [start, end]);
							}
						},
						{
							text: 'Last 2 Days',
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
								picker.$emit('pick', [start, end]);
							}
						},
						{
							text: 'Last week',
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
								picker.$emit('pick', [start, end]);
							}
						},
						{
							text: 'Last month',
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
								picker.$emit('pick', [start, end]);
							}
						},
						{
							text: 'Last 3 months',
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
								picker.$emit('pick', [start, end]);
							}
						}
					]
				}
			}
		},
		mounted() {
			this.fetchData().then();
			this.fetchWatcher = setInterval(() => {
				this.fetchData().then();
			}, 90000)
		},
		beforeDestroy() {
			clearInterval(this.fetchWatcher)
		},
		methods: {
			async fetchData() {
				try {
					this.busy = true;
					setTimeout(async () => {
						// Consider this to abort prev requests  https://github.com/pagekit/vue-resource/blob/develop/docs/recipes.md
						let $paginate = {...this.paginate, ...this.filters};
						let res = await this.$apix.sendHttpRequest('GET', 'visit/list', $paginate);
						this.list = res.rows;
						this.count = res.count;
						this.busy = false;
					}, 0)
				} catch (e) {
					console.error('[ERROR] Visits fetch: ', e);
					this.busy = false;
				}
			},
			showParams(params) {
				this.currentParams = [];
				Object.entries(params).forEach((e, i) => {
					this.currentParams.push({t: e[0], v: e[1]})
				});
				this.paramsDialogShown = !this.paramsDialogShown;
			},
		}
	}
</script>
