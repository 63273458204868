<template lang="pug">
	#section-reassign-queue-list-1
		el-row
			el-col(:span="24")
				el-row(:gutter="48")
					el-col(:xs="24")
						.text-left
							h5 List Of leads in queues
		el-row.mt-3
			el-col
				el-table(id="data-list", v-loading='busy', stripe="", :data='list', element-loading-text='Loading', header-row-class-name="dark-header", border='', fit='', highlight-current-row='')
					el-table-column(align="center" label="Queue UUID")
						template(slot-scope='scope')
							| {{ scope.row.uuid }}
					el-table-column(align="center")
						template(slot="header")
							label Last Exec.
							el-tooltip(content="Last execution time in the queue")
								el-icon.chooseable(name="question")
						template(slot-scope='scope')
							| {{ scope.row.last_attempt_formatted || '-' }}
					el-table-column(align="center" label="Next Exec.")
						template(slot="header")
							label Last Exec.
							el-tooltip(content="This is the estimated time while the next execution in the queue would be prepared. Notice - it is not the time when the lead will be reregistered")
								el-icon.chooseable(name="question")
						template(slot-scope='scope')
							| {{ scope.row.next_attempt_formatted || '-' }}
					el-table-column(align="center" label="E-Mail")
						template(slot-scope='scope')
							| {{ scope.row.lead.email }}&nbsp;
							small.text-success ({{ scope.row.lead.id }})
					el-table-column(align="center" label="Brand")
						template(slot-scope='scope')
							span.d-block <b>Original:</b> {{ scope.row.lead.brand.name }}
							span.d-block <b>Target:</b> {{ scope.row.dest_brand.name }}
					el-table-column(align="center" label="Funnel")
						template(slot-scope='scope')
							span.d-block <b>Original:</b> {{ scope.row.lead && scope.row.lead.funnel ? scope.row.lead.funnel.name : '-' }}
							span.d-block <b>Target:</b> {{ scope.row.dest_funnel.name }}
					el-table-column(align="center" label="Retries" :width="65")
						template(slot-scope="scope")
							span(v-bind:class="{'text-danger': scope.row.retries> 2}") {{ scope.row.retries }}
					el-table-column(align="center" label="Actions" :width="100")
						template(slot-scope='scope')
							el-button(size="mini" icon="el-icon-delete" type="danger" @click="removeEntry(scope.row.uuid, scope.row.lead.id)")

					// todo add a tabl for queues, add buttons "pause", "resume", "stop"
		.d-flex.justify-content-between
			b-pagination.mt-3(v-model="paginate.page", :total-rows="count", :per-page="paginate.limit", aria-controls="data-list", @change="fetchData")

			el-row.mt-4
				el-col(:span="24")
					label.label-text-gray
						b Total:&nbsp;
						| {{ count | numeral('0,0') }}

			el-row
				el-col(:span="24")
					el-select.mt-3(filterable="", v-model="paginate.limit", @change="fetchData")
						el-option(label="10 Per Page", :value="10")
						el-option(label="20 Per Page", :value="20")
						el-option(label="50 Per Page", :value="50")
						el-option(label="100 Per Page", :value="100")
						el-option(label="200 Per Page", :value="200")
</template>

<style lang="scss">

</style>

<script lang="js">
export default {
	name: 'reassign-queue',
	data() {
		return {
			fetchWatcher: null,
			busy: false,
			list: [],
			count: 0,
			paginate: {
				page: 1,
				keyword: '',
				limit: 10
			}
		}
	},
	mounted() {
		this.fetchData().then();
		this.fetchWatcher = setInterval(() => {
			this.fetchData().then();
		}, 30000)
	},
	beforeDestroy() {
		clearInterval(this.fetchWatcher)
	},
	methods: {
		async fetchData() {
			try {
				this.busy = true;
				setTimeout(async () => {
					let $paginate = {...this.paginate, ...this.filters};
					let res = await this.$apix.sendHttpRequest('GET', 'lead/queue-list', $paginate)
					this.list = res.rows;
					this.count = res.count;
					this.busy = false;
				}, 0)
			} catch (e) {
				console.error('[ERROR] Reassign Queue: ', e);
				this.busy = false;
			}
		},
		removeEntry(uuid, lead_id) {
			this.$confirm('Are you sure that you want to delete this entry? This operation cannot be undone!', 'Lead Reassign Discard Alert')
				.then(async () => {
					const result = await this.$apix.sendHttpRequest('DELETE', 'lead/remove-from-queue', { uuid, lead_id })
					await this.fetchData();
				})
				.catch(err => {
					console.error('[ERROR] Reassign Queue remove entry', err);
				})
		}
	}
}
</script>
